import { useEffect } from "react";

import { useRouter } from "next/router";

import { useTrackerContext } from "context/tracker";
import { TrackerState } from "types/types";
import { getLocale } from "utils/utils";

const useRedirect = () => {
  const router = useRouter();
  const { data: tracker, trackerID } = useTrackerContext();

  useEffect(() => {
    window.addEventListener(
      "popstate",
      function (event) {
        // go back twice in history
        history.go(-2);
      },
      false
    );

    return () => {
      window.removeEventListener("popstate", () => {});
    };
  });

  switch (tracker?.state) {
    case TrackerState.QUEUED:
      if ("/[id]/waiting" !== router.pathname) {
        router.push(`/${trackerID}/waiting`, undefined, { locale: getLocale(tracker?.language) });
      }
      break;
    case TrackerState.INACTIVE:
      if ("/[id]/feedback" !== router.pathname) {
        router.push(`/${trackerID}/feedback`, undefined, { locale: getLocale(tracker?.language) });
      }
      break;
    case TrackerState.ACTIVE:
      if ("/[id]/tracker" !== router.pathname) {
        router.push(`/${trackerID}/tracker`, undefined, { locale: getLocale(tracker?.language) });
      }
      break;
    default:
  }
};

export default useRedirect;
